"use strict";

// angular2
import {Component, Input} from "@angular/core";

@Component({
    selector: "mn-loading",
    templateUrl: "./MnLoading.html"
})
export class MnLoading {

    @Input() active:boolean;
    @Input() theme:string;
    @Input() position:string;

    constructor() {}

}