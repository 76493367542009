/**
 * Created by Joerg on 17.11.2017.
 */

// angular
import {Component} from '@angular/core';

//
//import {MnMiddle} from "./MnMiddle";

@Component({
    selector: 'mn-sticky-bottom',
    templateUrl: './MnStickyBottom.html',
    styleUrls: ['./MnStickyBottom.css']
})
export class MnStickyBottom {

    constructor() {}
}

