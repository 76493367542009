/**
 * Created by Joerg on 17.11.2017.
 */

import {Component} from '@angular/core';

@Component({
    selector: 'mn-left-center-right',
    templateUrl: './MnLeftCenterRight.html',
    styleUrls: ['./MnLeftCenterRight.css']
})
export class MnLeftCenterRight {
    constructor() {}
}

