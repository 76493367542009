/**
 * Created by joerg on 7/17/17.
 */

import {Component} from '@angular/core';

@Component({
    selector: 'mn-header',
    template: '<div>Test Header</div>'
})
export class MnHeader {
    constructor() {}
}
