/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component,Injectable} from '@angular/core';

@Injectable()
export class MnMatrixService {

    public Matrix:MnMatrix = null;

    constructor() {
        console.log('constructor MnMatrixService');
    }

}

@Component({
    selector: 'mn-matrix',
    templateUrl: './MnMatrix.html',
    providers: [MnMatrixService]
})
export class MnMatrix {

    mActive:boolean = false;

    constructor(private mMatrixService:MnMatrixService) {
        this.mMatrixService.Matrix = this;
    }

    ngOnDestroy() {
        this.mMatrixService.Matrix = null;
    }

}

