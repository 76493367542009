/**
 * Created by Joerg on 17.11.2017.
 */

// angular
import {Component,ContentChildren,TemplateRef,QueryList,HostBinding} from '@angular/core';

//
//import {MnMiddle} from "./MnMiddle";

@Component({
    selector: 'mn-middle-group,[mn-middle-group]',
    templateUrl: './MnMiddleGroup.html',
    styleUrls: ['./MnMiddleGroup.css']
})
export class MnMiddleGroup {

    @HostBinding('class.mn-middle-group') mMnMiddleGroup:boolean = true;

    constructor() {}

    /*ngOnInit() {
        //console.log("dddd1",this.CCCT);
        console.log("dddd2",this.mMiddleItems);
    }*/

}

