/**
 * Created by joerg on 7/17/17.
 */

/**
 * Created by joerg on 7/17/17.
 */

import {Component} from '@angular/core';

@Component({
    selector: 'mn-footer',
    template: '<div>Test Footer</div>'
})
export class MnFooter {
    constructor() {}
}
