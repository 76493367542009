/**
 * Created by joerg on 5/3/17.
 */

// angular
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

// uirouter
import { UIRouterModule } from '@uirouter/angular';

// mn
import { MnCoreModule } from '@mn/core';
import { MnRouteModule } from '@mn/route';

//
import { MnApp } from './MnApp';
import { MnHeader } from './MnHeader';
import { MnFooter } from './MnFooter';
import { MnNav } from './MnNav';
import { MnMiddle } from './MnMiddle';
import { MnMiddleGroup } from './MnMiddleGroup';
import { MnLeftCenterRight } from './MnLeftCenterRight';
import { MnNavTest } from './MnNavTest';
import { MnNavItems } from "./MnNavItems";
import { MnStickyBottom } from "./MnStickyBottom";
import { MnLoading } from "./MnLoading";
import { MnMatrix } from "./MnMatrix";
import { MnFrame } from "./MnFrame";

let directives = [
    MnApp,
    MnHeader,
    MnFooter,
    MnNav,
    MnNavItems,
    MnMiddleGroup,
    MnLeftCenterRight,
    MnNavTest,
    MnStickyBottom,
    MnLoading,
    MnMatrix,
    MnFrame,
];

@NgModule({
    imports: [
        CommonModule,
        UIRouterModule.forChild(),
        MnCoreModule,
        MnRouteModule,
    ],
    exports:[...directives,MnMiddle],
    declarations: [...directives,MnMiddle],
    entryComponents: directives
})
export class MnCommonModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: MnCommonModule
            //providers: []
        };
    }
}

export class mn_common {
    static configure():any {
        return [
            MnCommonModule
            /*MnRouteModule.forRoot(),
            UIRouterModule.forRoot({
                states: [],
                useHash: true
            })*/
        ];
    }
}