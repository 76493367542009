/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component,Input,Injector,Injectable,HostBinding,NgModuleFactory} from '@angular/core';

// uirouter
import {Transition,StateService, TransitionService} from '@uirouter/angular';

@Injectable()
export class MnFrameService {

    public Frame:MnFrame = null;

    constructor() {
        console.log('constructor MnFrameService');
    }

    public setHeaderLeft(dc:MnFrame.DynamicComponent) {
        this.Frame.setDynamicComponent('header','left',dc);
    }
    public setHeaderCenter(dc:MnFrame.DynamicComponent) {
        this.Frame.setDynamicComponent('header','center',dc);
    }
    public setHeaderRight(dc:MnFrame.DynamicComponent) {
        this.Frame.setDynamicComponent('header','right',dc);
    }

}

@Component({
    selector: 'mn-frame',
    templateUrl: './MnFrame.html',
    providers: [MnFrameService]
})
export class MnFrame {

    static EmptyComponent:MnFrame.DynamicComponent = {
        component: null,
        injector: null,
        content: null,
        factory: null
    };

    private mDynamicComponents:MnFrame.DynamicComponents = {
        header: {
            left: MnFrame.EmptyComponent,
            center: MnFrame.EmptyComponent,
            right: MnFrame.EmptyComponent,
        },
        footer: {
            left: MnFrame.EmptyComponent,
            center: MnFrame.EmptyComponent,
            right: MnFrame.EmptyComponent,
        },
    };

    @HostBinding('class') mTheme:string;
    @Input() set theme(value:string) {
        this.mTheme = value;
    }
    get theme():string {
        return this.mTheme;
    }

    private mName:string = '"';
    @Input() set name(value:string) {
        this.mName = value;
        this.updateData();
    }
    get name():string {
        return this.mName;
    }

    private mHeaderConfig:any = {};
    private mFooterConfig:any = {};
    private mTransOnSuccessDeregister:any;

    constructor(private mFrameService:MnFrameService, private mTransService:TransitionService, private mStateService:StateService) {
        this.mFrameService.Frame = this;
        this.mTransOnSuccessDeregister = this.mTransService.onSuccess({}, (trans) => this.updateData() );
    }

    private updateData() {
        console.log(this.mStateService.current.data);
        let mydata = this.mStateService.current.data[this.mName];
        if (mydata) {
            this.mHeaderConfig = mydata.header || {};
            this.mFooterConfig = mydata.footer || {};
        } else {
            this.mHeaderConfig = {};
            this.mFooterConfig = {};
        }
    }

    public setDynamicComponent(vertical:string, horizontal: string, dc:MnFrame.DynamicComponent) {
        if (dc) {
            this.mDynamicComponents[vertical][horizontal] = dc;
        } else {
            this.mDynamicComponents[vertical][horizontal] = MnFrame.EmptyComponent;
        }
    }

    ngOnDestroy() {
        this.mFrameService.Frame = null;
        this.mTransOnSuccessDeregister();
    }

}
export namespace MnFrame {
    export interface DynamicComponent {
        component: any;
        injector: Injector,
        content: any[][],
        factory: NgModuleFactory<any>

    }
    export interface DynamicComponentNav {
        left: DynamicComponent,
        center: DynamicComponent,
        right: DynamicComponent,
    }
    export interface DynamicComponents {
        header: DynamicComponentNav,
        footer: DynamicComponentNav,
    }
}

