/**
 * Created by joerg on 7/17/17.
 */

import {Component} from '@angular/core';

@Component({
    selector: 'mn-app',
    templateUrl: 'MnApp.html'
})
export class MnApp {
    constructor() {}
}
