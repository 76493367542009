/**
 * Created by Joerg on 17.11.2017.
 */

// angular
import {Component,Input,ChangeDetectionStrategy} from '@angular/core';

@Component({
    selector: '[mn-nav-items]',
    templateUrl: './MnNavItems.html',
    styleUrls: ['./MnNavItems.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MnNavItems {

    @Input('mn-nav-items') mItems;

    constructor() {}

}
