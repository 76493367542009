/**
 * Created by Joerg on 17.11.2017.
 */

import {Component} from '@angular/core';

let items = {
    left: [
        {
            text: "test",
            theme: "testclass"
        },
        { text: "mnam" },
        { image: "mnam" },
        { anchor: "mnam",
            group: [
                { text: "Hallo Welt"},
                { image: "mnam" }
            ]
        },
        { anchor: "mnam",
            text: "mnam",
            theme: "testclass"
        }
    ],
    center: [

    ],
    right: [

    ],
    height: 50,
    theme: "mn-nav-test-1"
};


@Component({
    selector: 'mn-nav-test',
    template: `
        <mn-nav theme="mn-nav-test-1" [config]="config1"></mn-nav>
        <mn-nav theme="mn-nav-test-2" [config]="config2">
            <ng-container mn-nav-center-middle>
                <mn-middle>MIDDLE</mn-middle>
            </ng-container>
            <ng-container mn-nav-left-middle>
                <mn-middle>LEFT</mn-middle>
            </ng-container>
            <ng-container mn-nav-right-middle>
                <mn-middle>RIGHT</mn-middle>
            </ng-container>
        </mn-nav>
        
        <mn-sticky-bottom style="height: 300px; width: 200px; background: red">
            <div mn-sticky-bottom-content style="background: gold">Seome Content</div>
            <div mn-sticky-bottom-footer style="background: lightgoldenrodyellow">Seome Footer</div>
        </mn-sticky-bottom>
        <button (click)="toggleLoading()">Toggle Loading</button>
        <div style="height: 400px; width: 400px; position: relative;">
            <mn-loading theme="loading-test" [active]="mLoading" position="absolute">
                <div>Line1</div>
                <div>Line2</div>
                <div>Line3</div>
            </mn-loading>
        </div>
    `
})
export class MnNavTest {
    constructor() {}

    /*config1:any = {
        theme: 'mn-nav-test-1'
    };*/
    config1:any = items;
    config2:any = {
        theme: 'mn-nav-test-2',
        right: items.left
    };

    mLoading:boolean = false;
    toggleLoading() {
        this.mLoading = !this.mLoading;
    }
}
