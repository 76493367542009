/**
 * Created by Joerg on 17.11.2017.
 */

import {Directive,Component,ViewChild,ContentChildren,TemplateRef,QueryList,Input,HostBinding} from '@angular/core';

@Directive({
    selector: 'mn-middle',
    //templateUrl: './MnMiddle.html',
    //styleUrls: ['./MnMiddle.css']
})
export class MnMiddle {

    @HostBinding('class.mn-middle') mMnMiddle:boolean = true;
    @HostBinding('style.font-size')@Input('font-size') mFontSize: string;
    @HostBinding('style.display')@Input('display') mDisplay: string;

    constructor() {}

    /*ngOnInit() {
        console.log("dddd1",this.CCCT);
        console.log("dddd2",this.defaultTabButtonsTpl);
    }*/

}

