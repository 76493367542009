/**
 * Created by Joerg on 17.11.2017.
 */

// angular
import {Component,Input,HostBinding,Optional,ChangeDetectionStrategy} from '@angular/core';

// mn
import {MnLayoutHeight} from '@mn/core';

@Component({
    selector: 'mn-nav',
    templateUrl: './MnNav.html',
    styleUrls: ['./MnNav.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MnNav {

    @HostBinding('class') mTheme:string;

    private mLeft:any[] = [];
    private mCenter:any[] = [];
    private mRight:any[] = [];
    private mConfig:any;
    @Input() set config(value:any) {
        this.mConfig = value;
        this.updateConfig();
    }
    get config():any {
        //console.log("config");
        return this.mConfig;
    }

    constructor(@Optional() private mLayoutHeight:MnLayoutHeight) {}

    private updateConfig() {
        //console.log("update Config");

        // adapt layout height if we are in a layout
        if (this.mLayoutHeight && this.mConfig.height) {
            this.mLayoutHeight.request(this.mConfig.height);
        }

        // adapt the theme if any
        if (this.mConfig.theme) {
            this.mTheme = this.mConfig.theme;
        }

        // update left center right
        this.mLeft = this.updateItems(this.mConfig.left);
        this.mCenter = this.updateItems(this.mConfig.center);
        this.mRight = this.updateItems(this.mConfig.right);
    }

    private updateItems(items:any[]):any[] {
        let result:any[] = [];
        if (!items) return result;
        return items;
    }

}
